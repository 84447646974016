<template>
  <div>
    <el-dialog width="70%" :title="!dataForm.id ? '新增' : '查看'" :close-on-click-modal="false" :visible.sync="visible"
      :before-close="handleCloseDialog">
      <h3 style="padding: 2vh 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">Push内容信息配置</h3>
      <el-form :model="dataForm" ref="dataForm" label-width="125px" :rules="dataRule">

        <el-form-item label="Push内容：" prop="pushContent">
          <el-input :readonly="this.dataForm.id !== 0" type="textarea" placeholder="请输入内容" v-model="dataForm.pushContent"
            class="input-width" resize="none" :rows="2">
          </el-input>
        </el-form-item>

        <el-form-item label="Push配图：" prop="pushImage">
          <el-upload class="avatar-uploader" :action="ossUploadUrl" :show-file-list="true"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :file-list="imageList" :limit="1"
            :on-remove="handleAvatarRemove" :disabled="this.dataForm.id !== 0">
            <img v-if="dataForm.pushImage" :src="dataForm.pushImage" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="跳转链接：" prop="jumpLink">
          <el-input :readonly="this.dataForm.id !== 0" placeholder="请输入内容" v-model="dataForm.jumpLink"
            class="input-width">
          </el-input>
        </el-form-item>

        <el-form-item label="打开方式：" prop="skip">
          <el-radio-group v-model="dataForm.skip" :disabled="this.dataForm.id !== 0">
            <el-radio :label="0">应用内打开</el-radio>
            <el-radio :label="1">应用外打开</el-radio>
          </el-radio-group>
        </el-form-item>

        <h3>Push推送信息配置</h3>

        <el-form-item label="发布时间：" prop="sendTime">
          <el-date-picker :disabled="this.dataForm.id !== 0" v-model="dataForm.sendTime" placeholder="任意时间点"
            value-format="yyyy-MM-dd HH:mm:ss" type="datetime"></el-date-picker>
        </el-form-item>

        <el-form-item label="是否人群包：" prop="isUserPackage">
          <el-radio-group v-model="dataForm.isUserPackage" :disabled="this.dataForm.id !== 0">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="dataForm.isUserPackage === 1" label="发送人群包：" prop="packageUrl">
          <el-upload class="upload-demo" :action="ossUploadFileUrl" :on-success="handleFileSuccess" :show-file-list="true"
            :on-remove="handleFileRemove" :on-preview="handlePreview" :file-list="fileList" :limit="1" accept=".txt">
            <el-button slot="trigger" size="small" type="primary" :disabled="this.dataForm.id !== 0">上传文件</el-button>
          </el-upload>
        </el-form-item>

        <div style="display: flex;" v-if="dataForm.isUserPackage === 0">
          <el-form-item label="发布国家：" prop="sendCountry">
            <el-select v-model="dataForm.sendCountry" multiple clearable placeholder="all" size="small"
              @change="selectCountryChange" :disabled="this.dataForm.id !== 0">
              <el-option v-for="item in countryCodeOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Push语种：" prop="sendLang">
            <el-select v-model="dataForm.sendLang" clearable multiple placeholder="all" size="small"
              @change="selectLangChange" :disabled="this.dataForm.id !== 0">
              <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="dataForm.id === 0">
          <el-button @click="handleCloseDialog">取消</el-button>
          <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </template>

      </span>
    </el-dialog>

  </div>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";

const sourceDataForm = {
  id: 0,
  pushImage: '',
  pushContent: '',
  sendCountry: [],
  sendLang: [],
  sendTime: '',
  isUserPackage: 0,
  packageUrl: '',
  jumpLink: '',
  skip: 0,
}
export default {
  data() {
    return {
      visible: false,
      fileList: [],
      imageList: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      countryCodeOpt: [
        {
          value: 'all',
          label: 'ALL'
        },
        // {
        //   value: 'US',
        //   label: 'US'
        // },
        // {
        //   value: 'CN',
        //   label: 'CN'
        // },
      ],

      langOpt: langOpt,

      dataRule: {
        // 'pushImage': [
        //   { required: true, message: 'Push图片不能为空', trigger: 'blur' }
        // ],
        // 'lang': [
        //   { required: true, message: 'Push语种不能为空', trigger: 'blur' }
        // ],
        'skip': [
          { required: true, message: '打开方式不能为空', trigger: 'blur' }
        ],
        'sendTime': [
          { required: true, message: '发送时间不能为空', trigger: 'blur' }
        ],
        'pushContent': [
          { required: true, message: '发送内容不能为空', trigger: 'blur' }
        ],
      },
    }
  },
  created() {
    this.ossUploadUrl = this.$http.adornUrl(
      `/sys/oss/upload/compress?token=${this.$cookie.get("token")}`
    );
    this.ossUploadFileUrl = this.$http.adornUrl(
      `/sys/oss/upload?token=${this.$cookie.get("token")}`
    );
  },
  methods: {
    init(row) {
      // console.log('row', row)
      this.imageList = []
      this.fileList = []

      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.dataForm.id = !row ? 0 : row.id

      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }

      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.visible = true

      const { pushImage, sendCountry, sendLang, sendTime, pushContent, isUserPackage, packageUrl, jumpLink, skip } = row
      this.dataForm.pushImage = pushImage
      sendLang.replace(/\[|]/g, '').replace(/["']/g, "").split(',').forEach(item => {
        this.dataForm.sendLang.push(item)
      })
      sendCountry.replace(/\[|]/g, '').replace(/["']/g, "").split(',').forEach(item => {
        this.dataForm.sendCountry.push(item)
      })
      this.dataForm.sendTime = sendTime
      this.dataForm.pushContent = pushContent
      this.dataForm.isUserPackage = isUserPackage
      this.dataForm.packageUrl = packageUrl
      this.dataForm.jumpLink = jumpLink
      this.dataForm.skip = skip

      this.fileList.push({
        uid: 1,
        url: packageUrl,
        name: packageUrl
      })

    },

    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },

    selectLangChange(val) {
      if (val.includes('all')) {
        this.dataForm.sendLang = val.filter(item => item == 'all')
      }
    },

    selectCountryChange(val) {
      if (val.includes('all')) {
        this.dataForm.sendCountry = val.filter(item => item == 'all')
      }
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 jpeg/png/gif 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },

    handlePreview(file) {
      // 上传文件路径存在时可预览
      if (this.dataForm.packageUrl) {
        window.open(
          this.dataForm.packageUrl,
          "_blank"
        );
      } else {
        this.$message.warning("暂不支持预览");
      }
    },


    handleAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.pushImage = res.url
      } else {
        this.$message.error('上传图片失败:' + res.msg)
      }
    },

    handleAvatarRemove(res, file) {
      this.dataForm.pushImage = ''
    },

    handleFileSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.packageUrl = res.url
      } else {
        this.$message.error('上传文件失败:' + res.msg)
      }
    },

    handleFileRemove(res, file) {
      this.dataForm.packageUrl = ''
    },

    handleCloseDialog() {
      this.visible = false
      this.fileList = []
      this.imageList = []
    },

    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          // console.log('dataForm', this.dataForm)
          this.dataForm.sendLang = this.dataForm.sendLang.length === 0 ? 'all' : (this.dataForm.sendLang.includes('all') ? 'all' : JSON.stringify(this.dataForm.sendLang))
          this.dataForm.sendCountry = this.dataForm.sendCountry.length === 0 ? 'all' : (this.dataForm.sendCountry.includes('all') ? 'all' : JSON.stringify(this.dataForm.sendCountry))
          this.$http({
            url: this.$http.adornUrl(`/admin/pushOperation/add`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm,
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              data.msg && this.$message.error(data.msg)
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 20px;
}

h3 {
  padding: 2vh 0;
  border-top: 1px solid rgba(184, 184, 184, 0.6)
}


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vw;
  height: 12vw;
  line-height: 12vw;
  text-align: center;
}

.avatar {
  width: 12vw;
  height: 12vw;
  display: block;
}

.input-width {
  width: 30vw;
  margin-bottom: 2vh;
}
</style>
